var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.uppy
        ? _c("dashboard", {
            attrs: {
              uppy: _vm.uppy,
              props: {
                showLinkToFileUploadResult: false,
                showProgressDetails: true,
                hideCancelButton: false,
                proudlyDisplayPoweredByUppy: false,
                note: "Folders with many files might take some time to appear. To upload a folder, drag it here.",
                metaFields: [{ id: "name", name: "Name", placeholder: "Name" }],
                inline: true,
                width: "100%",
                doneButtonHandler: false,
              },
            },
          })
        : _vm._e(),
      _vm.error
        ? _c(
            "v-alert",
            { staticClass: "mt-4", attrs: { text: "", type: "error" } },
            [_vm._v(_vm._s(_vm.error))]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }